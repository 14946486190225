.form-template {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
}
